"use client";

import React from "react";
import { Trans } from "@/i18n";
import { usePathname } from "next/navigation";

import { HEADER_EVENTS, ILoginConfig, GoogleOneTap } from "@plum/plum-nav";
import { CookieConsent } from "@plum/plum-ui-next";
import { ConsentBanner } from "@plum/ui-core";
import { useModalActions } from "@plum/plum-modals";

import { env } from "@/env.mjs";

import { useUserContext } from "@/data/UserProvider";

import { useCampaign } from "@/hooks/useCampaign";
import { HeaderNav } from "./HeaderNav";
import { useAppContext } from "@/data/AppProvider";
import { isString } from "@/utils/search";
import { parseCookies } from "nookies";
import { useFeatureFlagCookie } from "@plum/ui-hooks";

const initialLoginConfig: ILoginConfig = {
  autoFetchUserInfo: false,
  isFetchingUser: false,
  identityBaseUrl: env.NEXT_PUBLIC_BASE_URL,
  facebookAppId: env.NEXT_PUBLIC_FACEBOOK_APP_ID,
  googleAppId: env.NEXT_PUBLIC_GOOGLE_APP_ID,
  cdnUrl: env.NEXT_PUBLIC_CDN_URL,
};

type HeaderContainerProps = {
  token?: string;
  isServerComponents?: boolean;
};

export const HeaderContainer = ({ token, isServerComponents = false }: HeaderContainerProps) => {
  const [userState, setUserState] = useUserContext();
  const { open } = useModalActions();
  const appState = useAppContext();
  const { isCampaignLink } = useCampaign("tablet");

  const pathname = usePathname();

  const isHome = pathname === "/";
  const hasHero = isString(appState.hero.image) && appState?.hero?.image?.length;

  const handleLogin = (user) => {
    setUserState({ isFetching: false, completedLogin: true, user });
  };

  const loginConfig: ILoginConfig = Object.assign(
    {},
    initialLoginConfig,
    { userInfo: userState.user },
    { isFetchingUser: userState.isFetching }
  );

  const handleOpenUserSettings = () => open("userSettings");

  React.useEffect(() => {
    window.addEventListener(HEADER_EVENTS.HEADER_EVENT_SETTINGS_CLICK, handleOpenUserSettings);

    return () => {
      window.removeEventListener(HEADER_EVENTS.HEADER_EVENT_SETTINGS_CLICK, handleOpenUserSettings);
    };
  }, []);

  const { country } = parseCookies();

  // prettier-ignore
  const eeaCountryCodes = [
    "AT","BE","BG","HR","CY","CZ","DK","EE","FI","FR","DE","GR","HU","IS","IE","IT",
    "LV","LI","LT","LU","MT","NL","NO","PL","PT","RO","SK","SI","ES","SE","GB","CH",
  ];

  const consentEnabled = useFeatureFlagCookie("exp_google_consent");

  const showConsentBanner = eeaCountryCodes.includes(country) && consentEnabled;

  return (
    <>
      {showConsentBanner ? (
        <ConsentBanner />
      ) : (
        <CookieConsent>
          <Trans
            i18nKey="common:cookies.copy"
            //eslint-disable-next-line jsx-a11y/anchor-has-content
            components={[<a key="bold" href="/cookie-policy" target="_blank" />]}
          />
        </CookieConsent>
      )}

      {!isCampaignLink && (
        <GoogleOneTap
          fedCmEnabled
          config={loginConfig}
          onLogin={handleLogin}
          displayAllScreens={true}
        />
      )}
      <HeaderNav
        config={loginConfig}
        theme={isHome || hasHero ? "transparent" : "default"}
        hasShadow={false}
        hideHelp
        token={token}
        onLogin={handleLogin}
        isServerComponents={isServerComponents}
      />
    </>
  );
};
