import React from "react";
import { useTranslation } from "react-i18next";
import { SHeaderNavButton } from "./HeaderNavButton.styles";
import { UserSettingsModal as UserSettingsModalNew } from "@plum/ui-modals";
import { useModalActions, UserSettingsModal as UserSettingsModalOld } from "@plum/plum-modals";

interface ICurrencyAndLangButtonProps {
  rounded?: boolean;
  language?: string;
  currencyCode?: string;
}

export const CurrencyAndLangButtonOld = ({
  rounded,
  language,
  currencyCode,
}: ICurrencyAndLangButtonProps) => {
  // TODO: properly fix the CurrencyAndLang button so that it works on servercomponents homepage and non-servercomponents facet pages
  // without this hacky redundant component
  const { t } = useTranslation();
  const { open } = useModalActions();
  return (
    <button
      className={SHeaderNavButton({
        rounded,
        className: "max-lg:hidden",
      })}
      data-track-id="header-nav__user-settings-link"
      aria-label={t("common:nav.topBar.userSettings.buttonAriaLabel")}
      onClick={() => open("userSettings")}
    >
      <span>{`${getLanguageFromLocale(language || "en-GB").toUpperCase()}`}</span>
      <div className="mx-2 h-4 bg-gray-900 self-center w-[1px]"></div>
      <span>{`${getCurrencySymbolFromCode(currencyCode || "GBP")}`}</span>
    </button>
  );
};

export const getCurrencySymbolFromCode = (currencyCode: string) => {
  try {
    return Number(0)
      .toLocaleString("en", {
        style: "currency",
        currency: currencyCode,
        maximumSignificantDigits: 1,
      })
      .replace("0", "")
      .trim();
  } catch (e) {
    return "";
  }
};

export const getLanguageFromLocale = (language: string) => {
  if (language) {
    return language.split("-")[0];
  }

  return "";
};

CurrencyAndLangButtonOld.displayName = "CurrencyAndLangButtonOld";
